export const footerProps = [
  {
    title: 'Newsletter',
    url: '/newsletter',
  },
  {
    title: 'FAQ',
    url: '/faq-versicherungen',
  },
  {
    title: 'Expertenrat vom Versicherungsteam',
    url: '/terminbuchung',
  },
];

export const footerProps2 = [
  {
    title: 'Kontakt',
    url: '/kontakt',
  },
  {
    title: 'Impressum',
    url: '/impressum',
  },
  {
    title: 'Datenschutz',
    url: '/datenschutz',
  },
  {
    title: 'Privatsphäre-Einstellungen',
    url: '',
  },
];
