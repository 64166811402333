import React from 'react';
import { Grid } from '@material-ui/core';
import { SocialMediaModel, SocialMediaReference } from '../apiSocialMedia/interface/SocialMedia';
import { Icon } from '../style';
import trackButtonClick from '../../../../partials/button/utils';

interface SocialMediaIconsProps {
    data: SocialMediaModel | undefined,
    isLoading: boolean | undefined,
}
function SocialMediaIcons({ data, isLoading }: SocialMediaIconsProps) {
  const { pathname } = window.location;
  const handleButtonTracking = async (link: string, buttonTitle: string) => {
    await trackButtonClick({
      data: link, isDownload: false, location: pathname, index: 'NavBarSocialButtons',
    });
  };
  return (
    <Grid item>
      {!isLoading
      && data?.socialMediaReference?.map((icon: SocialMediaReference, key: number) => (
        <a
          target="_blank"
          href={icon.link ? icon.link : ''}
          onClick={() => handleButtonTracking(icon.link, icon.picture.imageAltText)}
          rel="noreferrer"
          key={key}
          style={{
            display: 'flex', justifyItems: 'center', float: 'left',
          }}
        >
          <Icon key={key} src={icon.picture.image ? icon.picture.image : ''} alt={icon.picture.imageAltText ? icon.picture.imageAltText : ''} />
        </a>
      ))}
    </Grid>
  );
}

export default SocialMediaIcons;
