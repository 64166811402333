import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ArticleLinkHref, LinkContainer } from '../style';
import { theme } from '../../../../theme';

interface ArticleProps {
  data: {
    pageSlug?: string,
    name: string
  };
}

function MenuItemLink({
  data,
}: ArticleProps) {
  const {
    name,
    pageSlug,
  } = data;

  return (
    <ArticleLinkHref
      key={name || ''}
      href={pageSlug || ''}
    >
      <LinkContainer>
        <ArrowForwardIcon
          fontSize="small"
          htmlColor={theme.color.blau1}
          className="arrow_item"
          aria-hidden="true"
          data-nosnippet
        />
      </LinkContainer>
      {name || ''}
    </ArticleLinkHref>
  );
}

export default MenuItemLink;
