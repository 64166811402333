import React, {
  createRef, useRef, useState, RefObject,
} from 'react';
import { Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ControlledMenu, MenuItem } from '@szhsin/react-menu';
import classNames from 'classnames';
import {
  GridContainer, LinkContainer, LinkMenu, MobileMenu, NoLink, SocialMediaDesktop, SubMenuContainer,
} from '../style';
import NavBarLogo from '../Logo';
import SocialMediaIcons from '../SocialMediaIcons';
import { SocialMediaModel } from '../apiSocialMedia/interface/SocialMedia';
import { Menupunkte, NavigationModel } from '../api/interface/Navigation';
import TopMenuLink from '../TopMenuLink';
import { MobileNav } from '../MobileNav/MobileNav';
import MenuItemLink from '../MenuItems';

interface TopMenuProps {
  isLoadingNavbar: boolean,
  navbarData:NavigationModel,
  onOpenTopicArea:(ev: React.MouseEvent<HTMLDivElement>)=> void,
  topicAreaOpen: { [key: string]: boolean },
  socialMediaData?: SocialMediaModel,
  isLoadingSocialMedia?: boolean,
  selectedTopicArea: string
}

function TopMenu({
  isLoadingNavbar,
  navbarData,
  onOpenTopicArea,
  topicAreaOpen,
  socialMediaData,
  isLoadingSocialMedia,
  selectedTopicArea,
}: TopMenuProps) {
  const ref = useRef<RefObject<HTMLDivElement>[]>(navbarData.menupunkte.map(() => createRef<HTMLDivElement>()));
  const [isPagesSubMenuOpen, setPagesSubMenuOpen] = useState<boolean[]>(navbarData.menupunkte.map(() => false));
  const topicIndex = navbarData.menupunkte.findIndex((menupunkt) => menupunkt.name === selectedTopicArea);
  const handlePagesSubMenu = (menuIndex: number) => {
    setPagesSubMenuOpen((prev) => prev.map((subMenuState, subMenuIndex) => (subMenuIndex === menuIndex || prev[subMenuIndex])));
  };
  const handleControlledMenuClose = () => setPagesSubMenuOpen((prev) => prev.map(() => false));
  const styleOpenMenu = (menupunkt:Menupunkte) => classNames({
    selected: topicAreaOpen[menupunkt.name],
  });

  return (
    <>
      <GridContainer container justifyContent="space-between" alignItems="center">
        <NavBarLogo />
        {!isLoadingNavbar && navbarData.menupunkte.map((menupunkte, index) => (
          <LinkMenu key={`LinkMenu${menupunkte.name}`}>
            {menupunkte.themenbereiche.length ? (
              <LinkContainer
                container
                id={menupunkte.name}
                onClick={(event) => {
                  onOpenTopicArea(event);
                }}
                alignItems="center"
                className={styleOpenMenu(menupunkte)}
                key={`LinkContainerThemenbereich${menupunkte.name}`}
              >
                <ArrowForwardIcon fontSize="small" aria-hidden="true" data-nosnippet />
                <NoLink>{menupunkte.name}</NoLink>
              </LinkContainer>
            ) : menupunkte.pages?.length ? (
              <LinkContainer
                container
                id={menupunkte.name}
                onClick={(event) => {
                  onOpenTopicArea(event);
                }}
                alignItems="center"
                className={styleOpenMenu(menupunkte)}
                key={`LinkContainerPage${menupunkte.name}`}
              >
                <ArrowForwardIcon fontSize="small" aria-hidden="true" data-nosnippet />
                <LinkContainer item ref={ref.current[index]} onClick={() => handlePagesSubMenu(index)} id={menupunkte.name} alignItems="center">
                  <NoLink>{menupunkte.name}</NoLink>
                </LinkContainer>
              </LinkContainer>
            ) : (
              <LinkContainer
                key={`LinkContainer${menupunkte.name}`}
                container
                alignItems="center"
                className={styleOpenMenu(menupunkte)}
              >
                <TopMenuLink data={{ name: menupunkte.name, pageSlug: menupunkte.pageSlug }} />
              </LinkContainer>
            )}
          </LinkMenu>
        ))}
        <SocialMediaDesktop item>
          <SocialMediaIcons data={socialMediaData} isLoading={isLoadingSocialMedia} />
        </SocialMediaDesktop>
        {!isLoadingNavbar && (
        <MobileMenu item>
          <Grid container justifyContent="flex-end" alignItems="center">
            <SocialMediaIcons data={socialMediaData} isLoading={isLoadingSocialMedia} />
            <MobileNav
              navbarData={navbarData}
            />
          </Grid>
        </MobileMenu>
        )}
      </GridContainer>
      <SubMenuContainer>
        <ControlledMenu
          transition
          state={isPagesSubMenuOpen[topicIndex] ? 'open' : 'closed'}
          anchorRef={ref.current[topicIndex]}
          onClose={handleControlledMenuClose}
        >
          {!isLoadingNavbar && navbarData.menupunkte.map((menupunkt) => (
            <Grid container key={`Grid${menupunkt.name}`}>
              {menupunkt.name === selectedTopicArea && menupunkt.pages?.length
                  && (
                    menupunkt.pages.map((page) => (
                      <MenuItem key={`MenuItemControlledMenu${page.name}`}>
                        <MenuItemLink data={{ pageSlug: page?.pageSlug, name: page?.name }} />
                      </MenuItem>
                    ))
                  )}
            </Grid>
          ))}
        </ControlledMenu>
      </SubMenuContainer>
    </>
  );
}
export default TopMenu;
